import type { RawEventDatesListFragment } from '../../gql/fragments/__generated/RawEventDatesList';
import type { Nullable } from '../../models/CustomUtilityTypes';

export default (
  data: MaybeRef<Nullable<unknown>>
): data is RawEventDatesListFragment => {
  const dataValue = toValue(data) as {
    eventDates?: unknown;
    cancelledEventDates?: unknown;
    __typename?: string;
  };
  return (
    !isEmpty(dataValue) &&
    dataValue.__typename === 'Event' &&
    Array.isArray(dataValue.eventDates) &&
    Array.isArray(dataValue.cancelledEventDates)
  );
};
