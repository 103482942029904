import dayjs from 'dayjs';
import type { MaybeRef } from 'vue';
import type { DatelistItem } from '../../../components/Article/Datelist/models';
import type { RawEventDateItemFragment } from '../../../gql/fragments/__generated/RawEventDateItem';
import type { BaseInfoTagItems } from '../../../models/BaseInfoTagItems';
import type { RawEventListItemFragment } from '../../../gql/fragments/__generated/RawEventListItem';
import type { ComposerDateTimeFormatting, ComposerTranslation } from '#i18n';
import type { Datelist } from '../../../components/Teaser/Box/models';
import type { Nullable } from '../../../models/CustomUtilityTypes';
import type { AtomsIconText } from '../../../models/Atoms';
import type { RawEventDatesListFragment } from '../../../gql/fragments/__generated/RawEventDatesList';
import type { i18nCtxObject } from '../../../models/i18nCtxObject';
import { ErrorMessage } from '../../../models/ErrorMessage';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export default (
  data: MaybeRef<
    Nullable<RawEventListItemFragment | RawEventDatesListFragment>
  >,
  i18nCtx: i18nCtxObject,
  dateIcon: MaybeRef<string> = 'ion:calendar-outline',
  timeIcon: MaybeRef<string> = 'ion:time-outline'
) => {
  const nextEventDateInfoTag = computed((): Nullable<BaseInfoTagItems> => {
    const { t, d } = i18nCtx;
    if (!t || !d) {
      console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
      return null;
    }

    const eventDatesCompleteList = computed(() =>
      buildCompleteSortedEventList(data)
    );

    if (isEmpty(eventDatesCompleteList)) {
      return null;
    }

    const nextEvent = getNextClosestEventConsideringDateAndTime(
      eventDatesCompleteList.value
    );

    const infoTags = [];

    const nextEventInfoTagText = formatEventDateDate(nextEvent, 'long', d);
    if (nextEventInfoTagText) {
      infoTags.push({
        icon: toValue(dateIcon),
        text: nextEventInfoTagText,
      });
    }

    const formattedTime = formatEventDateTime(nextEvent, d, t);
    if (formattedTime) {
      infoTags.push({
        icon: toValue(timeIcon),
        text: formattedTime,
      });
    }

    return {
      items: infoTags,
    };
  });

  const nextEventDateAndTimeConsideringDate = computed(
    (): Nullable<{ date: AtomsIconText; time: AtomsIconText }> => {
      const { t, d } = i18nCtx;
      if (!t || !d) {
        console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
        return null;
      }

      const eventDatesCompleteList = computed(() =>
        buildCompleteSortedEventList(data)
      );

      if (isEmpty(eventDatesCompleteList)) {
        return null;
      }

      const nextEvent = getNextClosestEventConsideringDate(
        eventDatesCompleteList.value
      );
      const nextEventDateText = formatEventDateDate(nextEvent, 'long', d);
      const formattedTime = formatEventDateTime(nextEvent, d, t);

      if (isEmpty(nextEventDateText)) {
        return null;
      }

      return {
        date: !isEmpty(nextEventDateText)
          ? {
              icon: toValue(dateIcon),
              text: nextEventDateText,
            }
          : null,
        time: !isEmpty(formattedTime)
          ? {
              icon: toValue(timeIcon),
              text: formattedTime,
            }
          : null,
      };
    }
  );

  const nextEventDateAndTimeConsideringDateAndTime = computed(
    (): Nullable<{ date: AtomsIconText; time: AtomsIconText }> => {
      const { t, d } = i18nCtx;
      if (!t || !d) {
        console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
        return null;
      }

      const eventDatesCompleteList = computed(() =>
        buildCompleteSortedEventList(data)
      );

      if (isEmpty(eventDatesCompleteList)) {
        return null;
      }

      const nextEvent = getNextClosestEventConsideringDateAndTime(
        eventDatesCompleteList.value
      );
      const nextEventDateText = formatEventDateDate(nextEvent, 'long', d);
      const formattedTime = formatEventDateTime(nextEvent, d, t);

      if (isEmpty(nextEventDateText)) {
        return null;
      }

      return {
        date: !isEmpty(nextEventDateText)
          ? {
              icon: toValue(dateIcon),
              text: nextEventDateText,
            }
          : null,
        time: !isEmpty(formattedTime)
          ? {
              icon: toValue(timeIcon),
              text: formattedTime,
            }
          : null,
      };
    }
  );

  const allEventDatesList = computed((): DatelistItem[] => {
    const { t, d } = i18nCtx;
    if (!t || !d) {
      console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
      return [];
    }

    const eventDatesCompleteList = computed(() =>
      buildCompleteSortedEventList(data)
    );

    if (isEmpty(eventDatesCompleteList)) {
      return [];
    }

    const cancelled =
      (toValue(data) as RawEventDatesListFragment).cancelled || null;

    const dateListItems = eventDatesCompleteList.value.map(
      (eventDate: RawEventDateItemFragment): DatelistItem => {
        return {
          date: formatEventDateDate(eventDate, 'long', d),
          time: formatEventDateTime(eventDate, d, t),
          cancelled: cancelled || eventDate.cancelled || false,
          ticketLink: eventDate.bookingLink,
        };
      }
    );

    return dateListItems;
  });

  const allEventDatesLinkList = computed((): Datelist => {
    const { d } = i18nCtx;
    if (!d) {
      console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
      return { items: [] };
    }

    const dataValue = toValue(data);

    if (isEmpty(dataValue)) {
      return { items: [] };
    }

    const eventDatesActive =
      (toValue(data)! as RawEventListItemFragment).eventDates || [];
    if (isEmpty(eventDatesActive)) {
      return { items: [] };
    }

    return {
      items: eventDatesActive!.slice(1).map((eventDate) => {
        return {
          text: formatEventDateDate(eventDate, 'short', d) || '',
          link: eventDate.bookingLink || '',
        };
      }),
    };
  });

  const isNextEventCancelled = computed((): boolean | null => {
    const eventDatesCompleteList = computed(() =>
      buildCompleteSortedEventList(data)
    );

    if (isEmpty(eventDatesCompleteList)) {
      return null;
    }

    return (
      getNextClosestEventConsideringDateAndTime(eventDatesCompleteList.value)
        ?.cancelled || false
    );
  });

  return {
    nextEventDateInfoTag,
    nextEventDateAndTimeConsideringDate,
    nextEventDateAndTimeConsideringDateAndTime,
    allEventDatesList,
    allEventDatesLinkList,
    isNextEventCancelled,
  };
};

function formatEventDateDate(
  eventDate: Nullable<RawEventDateItemFragment>,
  formatType: 'long' | 'short',
  d: ComposerDateTimeFormatting
): Nullable<string> {
  if (!eventDate) {
    return null;
  }

  const date = new Date(eventDate.date || '');
  return d(date, formatType);
}

function formatEventDateTime(
  eventDate: Nullable<RawEventDateItemFragment>,
  d: ComposerDateTimeFormatting,
  t: ComposerTranslation
): Nullable<string> {
  if (!eventDate || !eventDate.startTime) {
    return null;
  }

  const startTime = dayjs(
    eventDate.date + ' ' + eventDate.startTime,
    'YYYY-MM-DD HH:mm:ss'
  );

  const times = [startTime];
  if (eventDate.duration) {
    times.push(startTime.add(eventDate.duration, 'minute'));
  }

  return (
    times.map((t) => d(t.toDate(), 'time')).join(' - ') +
    ' ' +
    t('event.detail.date.time')
  );
}

function buildCompleteSortedEventList(
  data: MaybeRef<Nullable<RawEventListItemFragment | RawEventDatesListFragment>>
): RawEventDateItemFragment[] {
  const eventDatesActive: RawEventDateItemFragment[] =
    toValue(data)?.eventDates || [];
  const eventDatesCancelled: RawEventDateItemFragment[] = isRawEventDatesList(
    data
  )
    ? data?.cancelledEventDates || []
    : [];

  return [...eventDatesActive, ...eventDatesCancelled].sort((a, b) => {
    const dateA = dayjs(`${a.date}T${a.startTime || '00:00:00'}`);
    const dateB = dayjs(`${b.date}T${b.startTime || '00:00:00'}`);

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  });
}

function getNextClosestEventConsideringDateAndTime(
  eventDatesCompleteList: RawEventDateItemFragment[]
): Nullable<RawEventDateItemFragment> {
  return (
    eventDatesCompleteList
      .map((event) => ({
        ...event,
        eventDate: dayjs(`${event.date}T${event.startTime || '00:00:00'}`),
      }))
      .filter((event) => event.eventDate.isAfter(dayjs()))
      .sort((a, b) => a.eventDate.diff(b.eventDate))[0] || null
  );
}

function getNextClosestEventConsideringDate(
  eventDatesCompleteList: RawEventDateItemFragment[]
): Nullable<RawEventDateItemFragment> {
  return (
    eventDatesCompleteList
      .map((event) => ({
        ...event,
        eventDate: dayjs(event.date),
      }))
      .filter((event) => event.eventDate.isSameOrAfter(dayjs(), 'day'))
      .sort((a, b) => a.eventDate.diff(b.eventDate))[0] || null
  );
}
